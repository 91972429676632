//React
import * as React from 'react';

//Styles
import * as styles from './PageContainer.module.scss';

const PageContainer = ({children})=>{
    return(
        <div className={styles.pageFade}>
            {children}
        </div>
    )
}

export default PageContainer;