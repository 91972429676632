//React
import * as React from 'react';

//Styles
import * as styles from './Card.module.scss';

const Card = ({children, style, addedClasses})=>{
    return (
        <div className={[styles.Card, ...( addedClasses ? addedClasses : []) ].join(' ')} style={style}> 
            {children}
        </div>
    )
}

export default Card;