//React
import * as React from 'react';
import {useState} from 'react';

//Styles
import * as styles from './contact.module.scss';

//Components
import Layout from '../../components//Layout//Layout';
import Card from '../../components/Card/Card';
import Seo from '../../components/seo';

//images
import email from '../../images/mail-icon.png';
import phone from '../../images/phone-icon.png';
import linkedIn from '../../images/linkedin-logo.png';
import github from '../../images/github-logo.png';
import PageContainer from '../../components/PageContainer/PageContainer';
import copyIcon from '../../images/copy-icon.png';

const Contact = ({ location }) => {
    const [phoneClipped, setPhoneClipped] = useState(false);
    const [phoneAnimating, setPhoneAnimating] = useState(false);
    
        const [phoneState, setPhoneState] = useState({clipped: false, animating: false});
        const [emailState, setEmailState] = useState({clipped: false, animating: false});
        const [linkedInState, setLinkedInState] = useState({clipped: false, animating: false});
        const [githubState, setGithubState] = useState({clipped: false, animating: false});
   

    const copyToClipboard = (event) => {
        event.preventDefault();
        const id = event.target.id;
        navigator.clipboard.writeText(event.target.parentElement.parentElement.innerText.slice(0, -17));
        switch(id){
            case 'copyPhone':
                setPhoneState({clipped: false, animating: true})
                setTimeout(()=>{
                   setPhoneState({clipped: true, animating: true});
                }, 200)
                break;
            case 'copyEmail':
                  setEmailState({clipped: false, animating: true})
                setTimeout(()=>{
                   setEmailState({clipped: true, animating: true});
                }, 200)
                break;
            case 'copyLinkedIn':
                  setLinkedInState({clipped: false, animating: true})
                setTimeout(()=>{
                   setLinkedInState({clipped: true, animating: true});
                }, 200)
                break;
            case 'copyGithub':
                  setGithubState({clipped: false, animating: true})
                setTimeout(()=>{
                   setGithubState({clipped: true, animating: true});
                }, 200)
                break;
            default:
                return;
        } 
    }

    const revertClicked = (event)=>{
        const id = event.target.id;
        setTimeout(()=>{
            switch(id){
                case 'copyPhone':
                    setPhoneState({clipped: false, animating: false})
                    break;
                case 'copyEmail':
                    setEmailState({clipped: false, animating: false})
                    break;
                case 'copyLinkedIn':
                    setLinkedInState({clipped: false, animating: false})
                    break;
                case 'copyGithub':
                    setGithubState({clipped: false, animating: false})
                    break;
                default:
                    return;
            }
        }, 300)
    }
    
    return (
        <Layout location={location}>
            <Seo
                title={"Contact"}
                description={"Get in touch with with Dan Kolonay by phone, email, or LinkedIn."}
            />
            <main id='contact-page'>
                <PageContainer>
                    <Card addedClasses={[styles.contactCard]}>
                        <h1>Get in touch with me</h1>
                        <a href='tel:4128413194' target='_blank' rel="noopener noreferrer" className={styles.iconFlex} >
                            <div className={styles.contactIcon}>
                                <img src={phone} alt="Phone" />
                            </div >
                            412-841-3194
                            <div className={[styles.copy,  phoneState.clipped ? styles.copied : ''].join(' ')}>
                                <img id="copyPhone" src={copyIcon} alt="copy phone" onClick={copyToClipboard} onMouseLeave={revertClicked}/>
                                <div className={phoneState.animating ? [styles.saveToClipboard, styles.animateDIV].join(' ') : styles.saveToClipboard}>
                                    <p className={phoneState.animating ? styles.animateP : ''}>{phoneState.clipped ? '✔' : 'Save to Clipboard'}</p>
                                    <div></div>
                                </div>
                            </div>
                        </a>
                        <a href='mailto:daniel.kolonay@gmail.com' target='_blank' rel="noopener noreferrer" className={styles.iconFlex} >
                            <div className={styles.contactIcon}>
                                <img src={email} alt="Email" />
                            </div>
                            daniel.kolonay@gmail.com
                            <div className={[styles.copy, emailState.clipped ? styles.copied : ''].join(' ')}>
                                <img id="copyEmail" src={copyIcon} alt="copy email" onClick={copyToClipboard} onMouseLeave={revertClicked}/>
                                <div className={emailState.animating ? [styles.saveToClipboard, styles.animateDIV].join(' ') : styles.saveToClipboard}>
                                    <p className={emailState.animating ? styles.animateP : ''}>{emailState.clipped? '✔' : 'Save to Clipboard'}</p>
                                    <div></div>
                                </div>
                            </div>
                        </a>
                        <a href='https://www.linkedin.com/in/daniel-kolonay' target='_blank' rel="noopener noreferrer" className={styles.iconFlex} >
                            <div className={styles.contactIcon}>
                                <img src={linkedIn} alt="LinkedIn" />
                            </div>
                            linkedin.com/in/daniel-kolonay
                            <div className={[styles.copy, linkedInState.clipped ? styles.copied : ''].join(' ')}>
                                <img id="copyLinkedIn" src={copyIcon} alt="copy linkedin" onClick={copyToClipboard} onMouseLeave={revertClicked}/>
                                <div className={linkedInState.animating ? [styles.saveToClipboard, styles.animateDIV].join(' ') : styles.saveToClipboard}>
                                    <p className={linkedInState.animating ? styles.animateP : ''}>{linkedInState.clipped? '✔' : 'Save to Clipboard'}</p>
                                    <div></div>
                                </div>
                            </div>
                        </a>
                        <a href='https://www.github.com/dkolonay' target='_blank' rel="noopener noreferrer" className={styles.iconFlex} >
                            <div className={styles.contactIcon}>
                                <img src={github} alt="Github" />
                            </div>
                            github.com/dkolonay
                            <div className={[styles.copy, githubState.clipped ? styles.copied : ''].join(' ')}>
                                <img id="copyGithub" src={copyIcon} alt="copy github" onClick={copyToClipboard} onMouseLeave={revertClicked}/>
                                <div className={githubState.animating ? [styles.saveToClipboard, styles.animateDIV].join(' ') : styles.saveToClipboard}>
                                    <p className={githubState.animating ? styles.animateP : ''}>{githubState.clipped? '✔' : 'Save to Clipboard'}</p>
                                    <div></div>
                                </div>
                            </div>
                        </a>
                    </Card>
                </PageContainer>
            </main>
        </Layout>
    )
}

export default Contact;